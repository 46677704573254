$(document).ready(function () {
  /* =====  MOBILE MENU TOGGLE  ====== */
  $(".offCanvasToggler").click(function (event) {
    event.preventDefault();
    $(".off-canvas-nav").toggleClass("active");
    $(".fullscreen-overlay").toggleClass("active");
    $("html, body").toggleClass("no-scrolling");
  });

  $(".datepicker").pickadate({
    format: 'yyyy-mm-dd',
    formatSubmit: 'yyyy-mm-dd'
  });

  $('.timepicker').pickatime();

  $("body").on("click", '.submit_form', formHandler);

  // $(".table").footable();
});

// ==========[ FUNCTION TO HANDLE FORMS ]==========
function formHandler(event) {
  event.preventDefault();

  let formName = $(this).data("form");
  let loaderId = "#" + formName + "-loader";
  let formId = "#" + formName + "-form";
  let formSuccess = "#" + formName + "-success";
  let formDanger = "#" + formName + "-danger";
  let feedbackId = "#" + formName + "-feedback"
  let formBlockId = "#" + formName + "-form-block"


  let idPre = $(this).data("id-pre");
  if (idPre == undefined) {
    idPre = "";
  }
  // let form = $(formId);
  // let form_data = new FormData(form[0]);

  let form = $(formId);
  let formData = new FormData(form[0]);
  let data = "";
  $(loaderId).removeClass("d-none");
  formData.append("js_url", "js_set");
  $.ajax({
    url: '/ajax/',
    data: formData,
    type: "POST",
    contentType: 'application/json',

    processData: false,
    contentType: false,
    success: function (data) {
      data = JSON.parse(data);
      console.log(data.success);
      if (data.success) {
        if (data.redirect) {
          window.location.href = data.redirect;
        } else {
          // ==========[ RENDER HTML ]==========
          if (data.html) {
            replaceMultipleHtml(data.html);
          }

          $(loaderId).addClass("d-none");
          // ==========[ SET SUCCESS WRAPPER ]==========
          let useFeedbackWrapper = feedbackId;
          if (data.feedback_wrapper) {
            useFeedbackWrapper = data.feedback_wrapper;
          }

          let success_wrapper = $(useFeedbackWrapper);
          success_wrapper.removeClass("d-none");
          success_wrapper.empty();

          let formdanger = $(formDanger);
          formdanger.addClass("d-none");

          let formblock = $(formId);
          let formsuccess = $(formSuccess);
          formblock.addClass("d-none");
          formsuccess.removeClass("d-none");

          // ==========[ CREATE SUCCESS AND APPEND ]==========
          let success_message = '<div class="uk-alert-success" uk-alert>';
          success_message += '<a class="uk-alert-close" uk-close></a>';
          success_message += data.success_message;
          success_message += '</div>'
          success_wrapper.append(success_message);


          // ==========[ HIDE FORM ]==========
          if (!data.no_hide) {
            form.addClass("d-none");
          }

          // ==========[ SCROLL TO FORM TOP ]==========
          $([document.documentElement, document.body]).animate({
            scrollTop: $(formBlockId).offset().top
          }, 2000);
        }
      } else {
        $(loaderId).addClass("d-none");
        // ==========[ ADD ERROR MESSAGES ]==========
        let formdanger = $(formDanger);
        formdanger.removeClass("d-none");
        let formsuccess = $(formSuccess);
        formsuccess.addClass("d-none");


        let errors_wrapper = $(feedbackId);
        errors_wrapper.removeClass("d-none");
        errors_wrapper.empty();
        $(data.errors_messages).each(function (i, val) {
          let error = '<div class="uk-alert-danger" uk-alert>';
          error += '<a class="uk-alert-close" uk-close></a>';
          error += val;
          error += '</div>'
          errors_wrapper.append(error);
        });

        // ==========[ REMOVE PREVIOUS ERRORS ]==========
        $(form).find('.uk-form-danger').each(function () {
          $(this).removeClass('uk-form-danger');
        });

        // ==========[ ADD ERROR CLASS TO FIELDS ]==========
        $(data.errors_fields).each(function (i, val) {
          let label_val = idPre + val;
          $('#' + label_val).addClass('uk-form-danger');
          $("label[for=" + label_val + "]").addClass('uk-form-danger');
        });

        // ==========[ SCROLL TO FORM TOP ]==========
        $([document.documentElement, document.body]).animate({
          scrollTop: $(formBlockId).offset().top
        }, 2000);
      }
    }

  });
}

// ==========[ FUNCTION TO CHECK IF ELEMENT HAS AN ATTRIBUTE ]==========
$.fn.hasAttr = function (name) {
  return this.attr(name) !== undefined;
};

// ==========[ FUNCTION TO GET THE URL PARAMETERS ]==========
function getParams(url = "") {
  if (url == "") {
    url = location.href;
  }

  var queryString = url.substring(url.indexOf('?') + 1);
  var paramsArr = queryString.split('&');
  var params = {};

  for (var i = 0, len = paramsArr.length; i < len; i++) {
    var keyValuePair = paramsArr[i].split('=');
    if (keyValuePair[1] != undefined) {
      params[keyValuePair[0]] = keyValuePair[1];
    }
  }

  return params;
}

// ==========[ FUNCTION TO REPLACE HTML ELEMENT BY ID ]==========
function replaceHtml(parent, html) {
  $("#" + parent).empty();
  $("#" + parent).append(html);
}

// ==========[ FUNCTION TO REPLACE MULTIPLE HTML ELEMENTS BY ID ]==========
function replaceMultipleHtml(html_array) {
  $(html_array).each(function (i, val) {
    replaceHtml(val.parent_id, val.html);
  });
}

// ==========[ ADD THE URL PARAMETERS TO A FORMDATA OBJECT ]==========
function addParamsData(data) {
  let params = getParams();

  $.each(params, function (key, value) {
    data.append(key, value);
  });

  return data;
}

// ==========[ PUSH A URL PARAMETER ]==========
function pushUrlParameter(key, value) {
  let params = getParams();
  let params_length = arrayLength(params);
  let query_string = "";

  if (params_length == 0) {
    query_string = "?" + key + "=" + value;
  } else {
    params[key] = value;
    query_string = buildQueryString(params);
  }

  window.history.pushState({}, "", query_string);
}

// ==========[ PUSH MULTIPLE URL PARAMETERS ]==========
function pushUrlParameters(params = {}) {
  if (arrayLength(params) == 0) {
    params = getParams();
  }

  let query_string = buildQueryString(params);

  window.history.pushState({}, "", query_string);
}

// ==========[ SCROLLTO FUNCTION ]==========
function scrollTo(to = "body", time = 1000, padding = 0) {
  $([document.documentElement, document.body]).animate({
    scrollTop: $(to).offset().top - padding
  }, time);
}

// ==========[ TOGGLE A PAGE LOADER ]==========
function togglePageLoader(loader = ".page-loader") {
  let page_loader = $(loader);
  page_loader.toggleClass("d-none");
}

// ==========[ BUILD A QUERY STRING FOR ULR PARAMETERS ]==========
function buildQueryString(params = {}) {
  let params_length = arrayLength(params);
  let query_string = "";
  let first = true;

  if (params_length == 0) {
    params = getParams();
  }

  $.each(params, function (key, val) {
    if (val != "") {
      if (first) {
        first = false;
        query_string += "?" + key + "=" + val;
      } else {
        query_string += "&" + key + "=" + val;
      }
    }
  });

  return query_string;
}

// ==========[ RETURN THE COUNT OF AN ARRAY ]==========
function arrayLength(array) {
  return $.map(array, function (n, i) { return i; }).length
}

